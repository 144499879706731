/*
 * @Author: DY
 * @Date: 2021-11-25 12:06:48
 * @LastEditTime: 2021-11-25 12:09:14
 * @Description: 
 * @Param: 
 * @FilePath: \JT.Web\src\views\EBCHome\dailyData\remoteLoad.js
 */
export default function remoteLoad(url, hasCallback) {
    /**
     * 移除script标签
     * @param scriptElement script dom
     */
    function removeScript(scriptElement) {
        document.body.removeChild(scriptElement);
    }
    /**
     * 创建script
     * @param url
     * @returns {Promise}
     */
    function createScript(url) {
        const scriptElement = document.createElement('script');
        document.body.appendChild(scriptElement);
        const promise = new Promise((resolve, reject) => {
            scriptElement.addEventListener('load', e => {
                removeScript(scriptElement);
                if (!hasCallback) {
                    resolve(e);
                }
            }, false);
            scriptElement.addEventListener('error', e => {
                removeScript(scriptElement);
                reject(e);
            }, false);
            if (hasCallback) {
                window.____callback____ = function () {
                    resolve();
                    window.____callback____ = null;
                };
            }
        });
        if (hasCallback) {
            url += '&callback=____callback____';
        }
        scriptElement.src = url;
        return promise;
    }

    return createScript(url);
}