<!--
 * @Author: DY
 * @Date: 2020-08-31 20:25:29
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2022-10-20 17:35:29
 * @Describe:
-->
<template>
    <div class="dailyData_faodeMap">
        <div id="gaodeMap"></div>
    </div>
</template>
<script>
import remoteLoad from './JS/remoteLoad.js';

export default {
    name: 'daily-data-faode-map',
    components: {},
    props: {},
    data() {
        return {
            mapObj: null,
            object3Dlayer: null,
            geocoder: null,
            district: null,
            AMap: null,
            AMapUI: null,
            stationMarkers: [],
            regionalData: ['北京市', '河北省', '山东省', '河南省', '安徽省', '江苏省'],
            // 企业场站数据
            enterpriseStationData: {},
            // 生产中的场站数据
            productionStation: {},
        };
    },
    created() {},
    mounted() {},
    activated() {},
    update() {},
    beforeRouteUpdate() {},
    methods: {
        async initPage() {
            // 企业场站数据
            this.enterpriseStationData = this.$parent.enterpriseStationData;
            // 生产中的场站数据
            this.productionStation = this.$parent.productionStatisticsData.sczdCodeList;
            if (window.AMap && window.AMapUI) {
                this.initMap();
            } else {
                await remoteLoad(
                    'http://webapi.amap.com/maps?v=1.4.15&key=b4007ecc143884e79eab2c515ede82cf'
                );
                await remoteLoad('http://webapi.amap.com/ui/1.1/main.js');
                await this.initMap();
            }
        },
        async initMap() {
            const AMap = this.AMap = window.AMap;
            const AMapUI = this.AMapUI = window.AMapUI;

            AMapUI.loadUI(['misc/PositionPicker'], () => {
                const mapConfig = {
                    zoom: 7.2,
                    pitch: 50,
                    viewMode: '3D',
                    resizeEnable: true,
                    buildingAnimation: true, // 楼块出现是否带动画
                    center: [115.487549, 35.241531],
                    mapStyle: 'amap://styles/darkblue',
                };
                const mapObj = this.mapObj = new AMap.Map('gaodeMap', mapConfig);
                mapObj.on('complete', () => {
                    AMap.plugin(
                        [
                            'AMap.ToolBar',
                            'AMap.Object3DLayer',
                            'AMap.DistrictSearch',
                            'AMap.Lights',
                            'AMap.Geocoder',
                        ], () => {
                            mapObj.AmbientLight = new AMap.Lights.AmbientLight([1, 1, 1], 0.5);
                            mapObj.DirectionLight = new AMap.Lights.DirectionLight([0, 0, 1], [1, 1, 1], 1);

                            const object3Dlayer = this.object3Dlayer = new AMap.Object3DLayer();
                            mapObj.add(object3Dlayer);

                            const geocoder = this.geocoder = new AMap.Geocoder({
                                city: '010',
                            });

                            const district = this.district = new AMap.DistrictSearch({
                                subdistrict: 0,
                                extensions: 'all',
                                level: 'city',
                            });

                            const color = '#0088ffc';
                            const polygons = [];
                            const height = 6000;
                            const color_3Dlayer = 'rgba(150, 190, 217, 0.4)';
                            // 企业场站所在省
                            const regionalData = [];
                            // 企业场站所在区县
                            const districtCountyList = [];
                            // 企业Marker
                            const enterpriseMarkers = [];
                            if (this.enterpriseStationData.company_list?.length > 0) {
                                this.enterpriseStationData.company_list.forEach(item => {
                                    if ((item.latitude || '') !== '' && (item.longitude || '') !== '') {
                                        const lnglat = [item.longitude, item.latitude];
                                        geocoder.getAddress(lnglat, function (status, result) {
                                            if (status === 'complete' && result.info === 'OK') {
                                                // 所在省份
                                                if (regionalData.indexOf(result.regeocode.addressComponent.province) === -1) {
                                                    regionalData.push(result.regeocode.addressComponent.province);
                                                    const provinceName = result.regeocode.addressComponent.province;
                                                    district.search(provinceName, function (status, result) {
                                                        const bounds = result.districtList[0].boundaries;
                                                        if (bounds) {
                                                            for (let i = 0, l = bounds.length; i < l; i++) {
                                                                const polygon = new AMap.Polygon({
                                                                    map: mapObj,
                                                                    path: bounds[i],
                                                                    fillOpacity: 0.5,
                                                                    fillColor: color,
                                                                    strokeWeight: 1,
                                                                    strokeColor: color,
                                                                });
                                                                polygons.push(polygon);
                                                            }
                                                        }
                                                    });
                                                }
                                                // 所在区县
                                                if (districtCountyList.indexOf(result.regeocode.addressComponent.adcode) === -1) {
                                                    districtCountyList.push(result.regeocode.addressComponent.adcode);
                                                    const areaName = result.regeocode.addressComponent.adcode;
                                                    district.search(areaName, function (district_status, district_result) {
                                                        const bounds = district_result.districtList[0].boundaries;
                                                        const prism = new AMap.Object3D.Prism({
                                                            map: mapObj,
                                                            path: bounds,
                                                            height: height,
                                                            color: color_3Dlayer,
                                                        });
                                                        prism.transparent = true;
                                                        object3Dlayer.add(prism);
                                                    });
                                                }
                                            }
                                        });

                                        let imgUrl = null;
                                        if (item.is_relocation === 1) {
                                            imgUrl = require('./images/icon_yellow.png');
                                        } else if (item.is_online) {
                                            imgUrl = require('./images/icon_green.png');
                                        } else {
                                            imgUrl = require('./images/icon_gray.png');
                                        }
                                        const startIcon = new AMap.Icon({
                                            image: imgUrl,
                                            size: new AMap.Size(40, 46),
                                            imageSize: new AMap.Size(40, 46),
                                            imageOffset: new AMap.Pixel(0, 0),
                                        });
                                        const startMarker = new AMap.Marker({
                                            position: lnglat,
                                            icon: startIcon,
                                            offset: new AMap.Pixel(-13, -30),
                                        });
                                        startMarker.setLabel({
                                            offset: new AMap.Pixel(0, 0),
                                            content: item.company_name,
                                            direction: 'bottom',
                                        });
                                        enterpriseMarkers.push(startMarker);
                                    }
                                });
                            }
                            mapObj.add(enterpriseMarkers);

                            // 场站Marker
                            this.stationMarkers = [];
                            if (this.enterpriseStationData.station_list?.length > 0) {
                                this.enterpriseStationData.station_list.forEach(item => {
                                    if ((item.latitude || '') !== '' && (item.longitude || '') !== '') {
                                        const lnglat = [item.longitude, item.latitude];
                                        geocoder.getAddress(lnglat, function (status, result) {
                                            if (status === 'complete' && result.info === 'OK') {
                                                // 场站所在区县
                                                if (districtCountyList.indexOf(result.regeocode.addressComponent.adcode) === -1) {
                                                    districtCountyList.push(result.regeocode.addressComponent.adcode);
                                                    const areaName = result.regeocode.addressComponent.adcode;
                                                    district.search(areaName, function (district_status, district_result) {
                                                        const bounds = district_result.districtList[0].boundaries;
                                                        const prism = new AMap.Object3D.Prism({
                                                            map: mapObj,
                                                            path: bounds,
                                                            height: height,
                                                            color: color_3Dlayer,
                                                        });
                                                        prism.transparent = true;
                                                        object3Dlayer.add(prism);
                                                    });
                                                }
                                            }
                                        });
                                        let imgUrl = null;
                                        if ((this.productionStation || []).indexOf(item.station_id) > -1) {
                                            imgUrl = require('./images/4.png');
                                        } else if (item.is_relocation === 1) {
                                            imgUrl = require('./images/3.png');
                                        } else {
                                            imgUrl = require('./images/2.png');
                                        }
                                        const startIcon = new AMap.Icon({
                                            image: imgUrl,
                                            size: new AMap.Size(22, 22),
                                            imageSize: new AMap.Size(22, 22),
                                            imageOffset: new AMap.Pixel(0, 0),
                                        });
                                        const startMarker = new AMap.Marker({
                                            position: lnglat,
                                            icon: startIcon,
                                            offset: new AMap.Pixel(-13, -30),
                                            id: item.station_id,
                                        });
                                        startMarker.setLabel({
                                            offset: new AMap.Pixel(0, -6),
                                            content: `<div class='stationInfo'>${item.station_name}</div>`,
                                            direction: 'bottom',
                                        });
                                        this.stationMarkers.push(startMarker);
                                    }
                                });
                            }
                            mapObj.add(this.stationMarkers);

                            mapObj.on('zoomend', () => {
                                const zoom = mapObj.getZoom();
                                if (zoom >= 9) {
                                    this.$('.stationInfo').show();
                                } else {
                                    this.$('.stationInfo').hide();
                                }
                            });
                        }
                    );
                    this.$parent.loading = false;
                });
            });
        },

        loadMarkers() {
            const _this = this;
            if (this.mapObj) {
                this.mapObj.remove(this.stationMarkers);
                this.stationMarkers = [];
                // 生产中的场站数据
                this.productionStation = this.$parent.productionStatisticsData.sczdCodeList;
                this.$nextTick(async () => {
                    this.enterpriseStationData.station_list.forEach(item => {
                        if ((item.latitude || '') !== '' && (item.longitude || '') !== '') {
                            const lnglat = [item.longitude, item.latitude];
                            let imgUrl = null;
                            if ((this.productionStation || []).indexOf(item.station_id) > -1) {
                                imgUrl = require('./images/4.png');
                            } else if (item.is_relocation === 1) {
                                imgUrl = require('./images/3.png');
                            } else {
                                imgUrl = require('./images/2.png');
                            }
                            const startIcon = new _this.AMap.Icon({
                                image: imgUrl,
                                size: new _this.AMap.Size(22, 22),
                                imageSize: new _this.AMap.Size(22, 22),
                                imageOffset: new _this.AMap.Pixel(0, 0),
                            });
                            const startMarker = new _this.AMap.Marker({
                                position: lnglat,
                                icon: startIcon,
                                offset: new _this.AMap.Pixel(-13, -30),
                                id: item.station_id,
                            });
                            startMarker.setLabel({
                                offset: new _this.AMap.Pixel(0, -8),
                                content: `<div class='stationInfo'>${item.station_name}</div>`,
                                direction: 'bottom',
                            });
                            this.stationMarkers.push(startMarker);
                        }
                    });
                    this.mapObj.add(this.stationMarkers);
                });
            }
        },
    },
    filter: {},
    computed: {},
};
</script>

<style lang="stylus">
.dailyData_faodeMap
    height 100%
    position relative
    >div
        height 100%
    .amap-logo
        display none !important
    .amap-copyright
        opacity 0
    .amap-marker-label
        border none
        background: none
        color #fff
    .stationInfo
        display none
        transform: translateX(-50%)
</style>
